import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "pasienList";
const modelPath = "/perawatan/pasien/";

class PasienList extends ListModelBase {
  constructor() {
    super(modelName, modelPath);
  }
}

export default PasienList;