<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTAR PASIEN</p>
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <cari-input class="filter1" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small
              v-if="pasienList.length > 0"
              class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{
                pagination.startRowNum + pasienList.length - 1
              }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="pasien in pasienList"
              :key="pasien.id"
              :title="pasien.no_rm"
              :detailUrl="{ name: 'pasien-detail', params: { id: pasien.id } }"
            >
              <template v-slot:date>
                <span class="is-pulled-right has-text-grey">
                  <small class="tanggal">{{ pasien.nama.toLowerCase() }}</small>
                </span>
              </template>
              <template v-slot:content>
                <b-icon
                  pack="fas"
                  :icon="pasien.gender === 'Perempuan' ? 'venus' : 'mars'"
                ></b-icon>
                <span>{{ pasien.gender }}</span>
              </template>
            </box>
            <box v-if="pasienList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>
          <!-- <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="pasienList"
            :mobile-cards="false"
          >
            <b-table-column
              field="no"
              label="No"
              v-slot="props"
            >{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="no_rm" label="No RM" v-slot="props">{{ props.row.no_rm }}</b-table-column>
            <b-table-column field="nama" label="Nama" class="capitalize" v-slot="props">
              <router-link
                :to="{ name: 'pasien-detail', params: {id: props.row.id} }"
              >{{ props.row.nama.toLowerCase() }}</router-link>
            </b-table-column>
            <b-table-column field="gender" label="Gender" v-slot="props">
              <b-icon pack="fas" :icon="props.row.gender === 'Perempuan' ? 'venus' : 'mars'"></b-icon>
              <span class="gender-text">{{ props.row.gender }}</span>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>-->

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import PasienList from "../models/pasienList.js";

export default {
  name: "PasienList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
  },
  data() {
    // pasien list perlu di-declare disini agar ikut dihancurkan
    // ketika tidak di keep-alive.
    this.pasienListMdl = new PasienList();
    this.listMdl = this.pasienListMdl; // bikin alias
    return this.pasienListMdl.getObservables();
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

// @media screen and (max-width: 480px) {
//   .gender-text {
//     display: none;
//   }
// }
</style>
